body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-image: url("../images/bg_rock.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-size: 90%;
}

.scale {
  transform-origin: 0 0;
  transform-origin: 0 0;
  transform: scale(0.9, 0.9)
}

#rcorners {
  border-radius: 10px;
}

code {
  font-family: Baskerville, "Baskerville Old Face", "Goudy Old Style", Garamond, "Times New Roman", serif;
}

/* Wrapper */

@-moz-keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.particlesBackground {
  position: absolute !important;
}

#particles-js canvas {
  display: block;
  vertical-align: bottom;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity .8s ease, -webkit-transform 1.4s ease;
  transition: opacity .8s ease, transform 1.4s ease;
  background-image: url("../images/bg_rock.jpg");
}

#particles-js {
  background-image: url("../images/bg_rock.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -10;
  top: 0;
  left: 0
}

img {
  -moz-animation: smooth 0.5s forwards;
  -webkit-animation: smooth 0.5s forwards;
  -o-animation: smooth 0.5s forwards;
  -ms-animation: smooth 0.5s forwards;
  animation: smooth 0.5s forwards;
}

#smooth {
  -moz-animation: smooth 1.5s forwards;
  -webkit-animation: smooth 1.5s forwards;
  -o-animation: smooth 1.5s forwards;
  -ms-animation: smooth 1.5s forwards;
  animation: smooth 1.5s forwards;
}

#light {
  Color: "white";
  background-color: "white";
}

.navbar {
  background-image: url("../images/bg_rock.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.navbar .navbar-toggler .icon-bar {
  background-color: white;
}

/* [2] Transition property for smooth transformation of images */

.zoom {
  transition: transform .2s;
  /* Animation */
  margin: 0 auto;
}

/* [3] Finally, transforming the image when container gets hovered */

.zoom:hover {
  transform: scale(1.05);
}

#ovalOutline {
  border-radius: 25px;
  border: solid;
  border-width: thin;
  background-image: rgba(129, 139, 124, 0.1);
  padding: 0px 4px 0px;
  height: 6px;
  font-size: 0.9em;
  display: inline;
  word-break: keep-all;
  white-Space: nowrap;
  margin-right: 5px;
  line-height: 1.5em;
}